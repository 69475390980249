import React from "react";
import theme from "theme";
import { Theme, Text, Box, Link, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				Shenzhen Starnoon Technology Co., LTD Sports Bar
			</title>
			<meta name={"description"} content={"Where Every Game Night is a Home Run"} />
			<meta property={"og:title"} content={"Shenzhen Starnoon Technology Co., LTD Sports Bar"} />
			<meta property={"og:description"} content={"Where Every Game Night is a Home Run"} />
			<meta property={"og:image"} content={"https://axiolblade.live/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://axiolblade.live/img/723593.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://axiolblade.live/img/723593.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://axiolblade.live/img/723593.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://axiolblade.live/img/723593.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://axiolblade.live/img/723593.png"} />
			<meta name={"msapplication-TileImage"} content={"https://axiolblade.live/img/723593.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" color="--darkD2" quarkly-title="Contacts-8">
			<Override slot="SectionContent" flex-direction="row" align-items="flex-start" lg-flex-direction="column" />
			<Box
				min-width="100px"
				min-height="100px"
				width="60%"
				lg-width="100%"
				display="flex"
				flex-direction="column"
				justify-content="center"
				lg-margin="0px 0 30px 0px"
				align-items="flex-start"
				sm-margin="0px 0px 0 0px"
				lg-padding="0px 180px 0px 0px"
				sm-padding="0px 0 0px 0px"
				md-padding="0px 0px 0px 0px"
			>
				<Text margin="0px 0px 20px 0px" font="normal 600 38px/1.2 --fontFamily-sansHelvetica" color="--dark">
					Contact us
				</Text>
				<Text
					margin="0px 0px 50px 0px"
					font="normal 300 16px/1.5 --fontFamily-sansHelvetica"
					color="--darkD2"
					lg-margin="0px 0px 20px 0px"
					md-width="100%"
				>
					We invite you to become a part of our ongoing story. Join us at Shenzhen Starnoon Technology Co., LTD, where every game is an experience and every guest is part of the team.
				</Text>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				grid-gap="16px 24px"
				margin="0px 0 0 10%"
				lg-grid-gap="36px 34px"
				flex-direction="column"
				width="50%"
				lg-margin="0px 0 0 0"
				lg-width="100%"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="row"
					border-color="--color-lightD2"
					border-radius="4px"
					align-items="center"
					margin="0px 0px 30px 0px"
					lg-margin="0px 0px 10px 0px"
					sm-margin="0px 0px 0 0px"
				>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						flex-direction="column"
						align-items="flex-start"
						md-justify-content="center"
					>
						<Text
							color="--dark"
							font="normal 500 25px/1.5 --fontFamily-sansHelvetica"
							display="block"
							margin="0px 0px 5px 0px"
							sm-font="normal 500 22px/1.5 --fontFamily-sansHelvetica"
						>
							Room 301, Building A, No.59 Ainan Road, Longdong Community
						</Text>
						<Text
							margin="0px 0px 0 0px"
							font="normal 300 16px/1.2 --fontFamily-sans"
							text-align="center"
							color="--darkD2"
							md-margin="0px 0px 0px 0px"
						>
							Baolong Street, Longgang District, Shenzhen
						</Text>
					</Box>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="row"
					border-color="--color-lightD2"
					border-radius="4px"
					align-items="center"
					margin="0px 0px 30px 0px"
					lg-margin="0px 0px 10px 0px"
					sm-margin="0px 0px 0 0px"
				>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						flex-direction="column"
						align-items="flex-start"
						md-justify-content="center"
					>
						<Link
							href="tel:86-13430775834"
							color="--dark"
							text-decoration-line="initial"
							font="normal 500 25px/1.5 --fontFamily-sansHelvetica"
							display="block"
							margin="0px 0px 5px 0px"
							sm-font="normal 500 22px/1.5 --fontFamily-sansHelvetica"
						>
							86-13430775834
						</Link>
					</Box>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="row"
					border-color="--color-lightD2"
					border-radius="4px"
					align-items="center"
				>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						flex-direction="column"
						align-items="flex-start"
						md-justify-content="center"
					>
						<Link
							href="mailto:info@rivieracasinofun.gmail"
							color="--dark"
							text-decoration-line="initial"
							font="normal 500 25px/1.5 --fontFamily-sansHelvetica"
							display="block"
							margin="0px 0px 5px 0px"
							sm-font="normal 500 22px/1.5 --fontFamily-sansHelvetica"
						>
							info@rivieracasinofun.gmail
						</Link>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Foopter />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});